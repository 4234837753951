<template>
    <Teleport :to="SEQUENCES_CONTROL_ID" :key="uniquePropsKey" v-if="chunkDefinition && !chunkDefinition.arrowHidden && Object.values(activatorBounding).some(a => a !== 0)">
        <div
                :class="classes"
                :style="style"
                :id="uniqueId"
        >
            <img src="/Arrow.svg">
        </div>
    </Teleport>
</template>

<script>
    import { SequenceStepChunkWithArrow } from '@/sequences/SequenceStepChunk/chunk_with_arrow/index'
    import { generateUUID, getAbsoluteBoundingRect } from '@/utils/misc'
    import activator_position_watcher from '@/sequences/SequenceStepChunk/utils/activator_position_watcher'
    import unique_id_mixin from '@/sequences/SequenceStepChunk/utils/unique_id_mixin'

    const BASE_CLASS = 'chunk-with-arrow'
    const applyBaseClassModificator = modificator => `${BASE_CLASS}--${modificator}`

    const POSITIONING_CLASSES = {
        [SequenceStepChunkWithArrow.POSITIONS.TOP]: applyBaseClassModificator`top`,
        [SequenceStepChunkWithArrow.POSITIONS.RIGHT]: applyBaseClassModificator`right`,
        [SequenceStepChunkWithArrow.POSITIONS.BOTTOM]: applyBaseClassModificator`bottom`,
        [SequenceStepChunkWithArrow.POSITIONS.LEFT]: applyBaseClassModificator`left`
    }

    export default {
        name: "ChunkWithArrow",
        _name: "ChunkWithArrow",
        mixins: [activator_position_watcher, unique_id_mixin],
        components: {
            Teleport: () => import('vue2-teleport')
        },
        // render() {
        //     return null
        // },
        props: {
            chunkDefinition: {
                type: Object,
                required: true
            },
            anchorSelector: {
                type: String,
                required: true
            }
        },
        computed: {
            classes() {
                return [
                    BASE_CLASS,
                    POSITIONING_CLASSES[this.chunkDefinition.position]
                ]
            },
            style() {
                if (!this.isMounted_)
                    return
                const toReturn = {'z-index': 9999}
                const position = this.chunkDefinition.position

                if (position === SequenceStepChunkWithArrow.POSITIONS.TOP)
                    toReturn.top = this.activatorBounding.top - 70 + 'px'
                if (position === SequenceStepChunkWithArrow.POSITIONS.RIGHT) {
                    toReturn.left = this.activatorBounding.right + 20 + 'px'
                    toReturn.transform = 'rotate(90deg)'
                }
                if (position === SequenceStepChunkWithArrow.POSITIONS.BOTTOM) {
                    toReturn.transform = 'rotate(180deg)'
                    toReturn.top = this.activatorBounding.bottom + 'px'
                }
                if (position === SequenceStepChunkWithArrow.POSITIONS.LEFT) {
                    toReturn.left = this.activatorBounding.left - 60 + 'px'
                    toReturn.transform = 'rotate(-90deg)'
                }
                if (position === SequenceStepChunkWithArrow.POSITIONS.TOP || position === SequenceStepChunkWithArrow.POSITIONS.BOTTOM)
                    toReturn.left = this.activatorBounding.left + 'px'


                if (position === SequenceStepChunkWithArrow.POSITIONS.RIGHT || position === SequenceStepChunkWithArrow.POSITIONS.LEFT)
                    toReturn.top = this.activatorBounding.top - 20 + 'px'

                return Object.entries(toReturn).map(([k, v]) => `${k}:${v}`).join(';')
            }
        },
        watch: {
            // classes(v) {
            //     if (this.getEl()) {
            //         this.getEl().removeAttribute('class')
            //
            //         this.getEl().classList.add(...v)
            //     }
            // },
            // style: {
            //     handler(v) {
            //         if (this.getEl()) {
            //             this.getEl().style = v
            //         }
            //     },
            //     immediate: true
            // }
        },
        mounted() {
            // if (!this.getEl()) {
            //     const el = document.createElement('div')
            //     el.id = this.uniqueId
            //     el.innerHTML = `<img src="/Arrow.svg">`
            //
            //     el.classList.add(...this.classes)
            //     el.style = this.style
            //
            //     document.querySelector(this.SEQUENCES_CONTROL_ID).appendChild(el)
            //
            //     setTimeout(this.handleWindowResize, 100)
            // }
        },
        beforeDestroy() {
            // if (this.getEl())
            //     document.querySelector(this.SEQUENCES_CONTROL_ID).removeChild(this.getEl())
        },
    }
</script>

<style lang="scss">
    .chunk-with-arrow {
        position: absolute;
        top: 10px;

        img {
            width: 35px;
            height: auto;
        }
    }
</style>
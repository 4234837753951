export default {
    props: {
        sequenceId: String,
        stepId: String,
        chunkId: String,
        chunkDefinition: Object
    },
    computed: {
        uniqueId() {
            return `${this.sequenceId}---${this.stepId}---${this.chunkId}---${this.chunkDefinition.constructor.name}---${this.$options._name}`
        },
        uniquePropsKey() {
            return JSON.stringify(this.$props)
        }
    },
    methods: {
        getEl() {
            return document.querySelector('#' + this.uniqueId)
        }
    }
}